import axiosPost from "../axiosPost";
import { UserCredentialsType } from "../../../types/types";
import { AxiosPostBody } from "../../../types/AxiosPost";

type Args = UserCredentialsType & {
  merchantNominaId: number; // Es requerido
  merchantLiquidacionDetalle: {
    merchantNominaEmpleadoId: number;
    merchantLiquidacionMonto: number;
    merchantLiquidacionBono?: number;
  }[]; // Es requerido
  merchantLiquidacionPeriodo: Date; // Es requerido
  merchantLiquidacionFechaAcreditacion: Date; // Es requerido
  merchantLiquidacionNotas: string; // Es requerido
};

export const MerchantLiquidacionIns = async (args: Args) => {
  const body: AxiosPostBody = {
    service: "MerchantLiquidacionIns",
    params: {
      merchantNominaId: args.merchantNominaId,
      merchantLiquidacionDetalle: args.merchantLiquidacionDetalle,
      merchantLiquidacionPeriodo: args.merchantLiquidacionPeriodo,
      merchantLiquidacionFechaAcreditacion:
        args.merchantLiquidacionFechaAcreditacion,
      merchantLiquidacionNotas: args.merchantLiquidacionNotas,
    },
  };

  try {
    const result = await axiosPost("Y", body, args);

    return result.result;
  } catch (err) {
    console.log(err);
  }
};
