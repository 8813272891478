import {
  TextField,
  Box,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import imgInfo from "../../../assets/icons/info.svg";

export function FormikTextInput({
  data,
  name,
  formik,
  disabled,
  className,
  variant = false,
  fullWidth = false,
  type
}) {
  return (
    <Box
      display="flex"
      justifyContent={"center"}
      // alignItems={"center"}
      flexDirection={"column"}
      width={"100%"}
      maxWidth={type === "filtro" ? "300px" : "100%"}
    >
      <TextField
        label={data[name].label}
        name={name}
        placeholder={data[name].placeholder}
        margin="normal"
        type="text"
        disabled={disabled}
        value={formik.values[name]}
        onChange={formik.handleChange}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        InputLabelProps={{ shrink: true }}
        fullWidth={fullWidth}
        className={className}
        variant={variant ? variant : "standard"}
      />
      {formik?.errors[name] && (
        <Typography variant="subtitle1" color={"error"} fontSize={"12px"}>
          {formik.errors[name]}
        </Typography>
      )}
    </Box>
  );
}

export function FormikNumberInput({
  data,
  name,
  formik,
  disabled,
  className,
  onChange,
  value,
  press,
  placeholder
  
}) {
  return (
    <TextField
      label={data[name]?.label}
      name={name}
      placeholder={placeholder || data[name].placeholder}
      margin="normal"
      type="text"
      disabled={disabled}
      value={value ? value : formik.values[name]}
      onChange={onChange ? onChange : formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      InputLabelProps={{ shrink: true }}
      fullWidth={className ? false : true}
      className={className}
      onKeyDown={press ? press : () => {}}
      variant="standard"
    />
  );
}

export function FormikSelectInput({
  data,
  name,
  formik,
  disabled,
  options,
  optionKey,
  optionValue,
  optionName,
  className,
  fullWidth = false
}) {
  return (
    <TextField
      label={data[name].label}
      name={name}
      margin="normal"
      select
      disabled={disabled}
      SelectProps={{ native: true }}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      InputLabelProps={{ shrink: true }}
      fullWidth={fullWidth}
      className={className}
      variant="standard"
    >
      {options.map((option) => {
        return (
          <option key={option[optionKey]} value={option[optionValue]}>
            {option[optionName]}
          </option>
        );
      })}
    </TextField>
  );
}

export function FormikYesOrNoInput({
  data,
  name,
  formik,
  disabled,
  className,
}) {
  return (
    <TextField
      label={data[name].label}
      name={name}
      margin="normal"
      select
      disabled={disabled}
      SelectProps={{ native: true }}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      InputLabelProps={{ shrink: true }}
      fullWidth={className ? false : true}
      className={className}
      variant="standard"
    >
      <option value="Y">Si</option>
      <option value="N">No</option>
    </TextField>
  );
}

export function FormikDateInput({
  data,
  name,
  formik,
  disabled,
  className,
  type = "date",
  required = false,
}) {
  return (
    <TextField
      required={required}
      label={data[name].label}
      name={name}
      placeholder={data[name].placeholder}
      margin="normal"
      type={type}
      disabled={disabled}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      InputLabelProps={{ shrink: true }}
      fullWidth={className ? false : true}
      className={className}
      variant="standard"
    />
  );
}

export function FormikTimeInput({ data, name, formik, disabled, className }) {
  return (
    <TextField
      label={data[name].label}
      name={name}
      placeholder={data[name].placeholder}
      margin="normal"
      type="time"
      disabled={disabled}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      InputLabelProps={{ shrink: true }}
      fullWidth={className ? false : true}
      className={className}
      variant="standard"
    />
  );
}
export function FormikRadioGroup({
  name,
  formik,
  label,
  info,
  className,
  disabled,
}) {
  return (
    <>
      <FormControl disabled={disabled}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormLabel id="demo-controlled-radio-buttons-group">
            {label}
          </FormLabel>
          {info && (
            <div className="container-info">
              <img src={imgInfo} alt="i" style={{ display: "flex" }} />
              <section className="data">{info}</section>
            </div>
          )}
        </div>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name={name}
          value={formik.values[name]}
          onChange={formik.handleChange}
        >
          <FormControlLabel control={<Radio />} label="Si" value={true} />
          <FormControlLabel control={<Radio />} label="No" value={false} />
        </RadioGroup>
      </FormControl>
      {formik?.errors[name] && (
        <Typography variant="subtitle1" color={"error"} fontSize={"12px"}>
          {formik.errors[name]}
        </Typography>
      )}
    </>
  );
}
