import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Box, Typography } from "@mui/material";
import moment from "moment";

import { MerchantLiquidacionIns } from "../../../../services/hooli-services";

import XGridDemo from "../../../utils/grid/Xgrid";
import SubmitButton from "../../../utils/button/SubmitButton";
import { transferencias as transferenciasTitles } from "../../../../consts/titulos-de-tablas";
import { formatNumber } from "../../../../services/Local-services";

import { removeAddedOption } from "../../utils";

import styles from "./index.module.scss";
import { MerchantLiquidacionDetalleGet } from "../../../../services/hooli-services/Liquidaciones/MerchantLiquidacionDetalleGet";

const SeeTransferForm = ({
  payrollsOptions,
  credentials,
  classes,
  style,
  retrieveTransfers,
  handleCloseModal,
  row,
}) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [disabledForm, setDisabledForm] = useState(false);

  const filteredOption = removeAddedOption(payrollsOptions);

  const createTransferFormik = useFormik({
    initialValues: {
      nominaId: filteredOption[0]?.merchantNominaId,
      periodo: "",
      fechaDePago: "",
      notas: "",
      detalle: [],
    },
    onSubmit: async (values, { resetForm }) => {
      if (!firstLoad) {
        setFirstLoad(true);
        return;
      }
      await MerchantLiquidacionIns({
        merchantNominaId: values.nominaId,
        merchantLiquidacionDetalle: JSON.stringify(
          values.detalle.map((item) => ({
            merchantNominaEmpleadoId: item.merchantNominaEmpleadoId,
            merchantLiquidacionMonto: Number(item.merchantLiquidacionMonto),
            merchantLiquidacionBono:
              Number(item.merchantLiquidacionBono) ?? undefined,
          }))
        ),
        merchantLiquidacionPeriodo: moment(values.periodo).format("MM/YYYY"),
        merchantLiquidacionFechaAcreditacion: moment(
          values.fechaDePago
        ).toISOString(true),
        merchantLiquidacionNotas: values.notas,
      });
      retrieveTransfers();
      handleCloseModal(resetForm);
    },
  });

  const totalTransferAmount = createTransferFormik.values.detalle.reduce(
    (acc, current) => acc + current.merchantLiquidacionMonto,
    0
  );

  const totalBonusAmount = createTransferFormik.values.detalle.reduce(
    (acc, current) => acc + (current.merchantLiquidacionBono || 0),
    0
  );

  useEffect(() => {
    const getInfo = async () => {
      const result = await MerchantLiquidacionDetalleGet({
        merchantLiquidacionId: row.id,
      });
      setEmployeeList(employeesMapper(result));
    };
    getInfo();
  }, [firstLoad, row.id]);

  const employeesMapper = (employeesList = []) =>
    employeesList.map((employee) => ({
      "CUIT/CUIL": employee.merchantEmpleadoCUIT,
      Nombre: employee.merchantEmpleadoDenominacion,
      id: employee.merchantEmpleadoId,
      "Monto ARS": employee.merchantLiquidacionDetalleMonto,
      "Monto HC": employee.merchantLiquidacionDetalleBono,
      "Resultado": employee.merchantLiquidacionDetalleResultado,      // merchantEmpleadoTipo: employee.merchantEmpleadoTipo,
    }));

  const createTransferData = {
    nominaId: { label: "Nómina" },
    periodo: { label: "Ingresá el período" },
    fechaDePago: { label: "Ingresa la fecha de pago" },
    notas: { label: "Notas" },
    ...employeeList.reduce((acc, employee) => {
      acc[`transferencia_${employee.id}`] = {};
      acc[`bono_${employee.id}`] = {};
      return acc;
    }, {}),
  };
  return (
    <form onSubmit={createTransferFormik.handleSubmit}>
      <Box
        sx={{
          ...style,
          height: !!employeeList.length ? "100%" : "fit-content",
          width: !!employeeList.length ? "60%" : "fit-content",
        }}
      >
        <Typography variant="h6" component="h2">
          Ver liquidación
        </Typography>
        <>
          <div className={`row-grid ${styles.grid}`} id="grid-container">
            <XGridDemo
              rows={employeeList}
              titles={transferenciasTitles}
              withoutRowSelection
              formik={createTransferFormik}
              data={createTransferData}
              type="see"
            />
          </div>
        </>
        {firstLoad && (
          <div className={styles.formFooter}>
            <div className={styles.totalLabelsContainer}>
              <h4>Totales</h4>
              <div className={styles.amountLabels}>
                <h4>Monto ARS: {"$" + formatNumber(totalTransferAmount)}</h4>
                <h4>Monto HC: {"$" + formatNumber(totalBonusAmount)}</h4>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            gap: "4rem",
            alignItems: "center",
            marginBottom: "6px",
          }}
        >
          <div
            onClick={() => handleCloseModal(createTransferFormik.resetForm)}
            style={{ cursor: "pointer" }}
          >
            Cerrar
          </div>
        </div>
      </Box>
    </form>
  );
};

export default SeeTransferForm;
